// const serverUrl = "https://localhost:5001/";
// export const domainName = "https://192.168.1.146:8080/";

// const serverUrl = "https://devapi.biochi.co/";
// export const domainName = "https://dev.biochi.co/";

const serverUrl = "https://api.biochi.co/";
export const domainName = "https://biochi.co/";




const domainUrl = "";
const versionUrl = "v1/";
export const apiUrlRoot = `${serverUrl}${domainUrl}`;
const apiUrlRootApi = `${apiUrlRoot}api/`;
const apiVersionUrl = apiUrlRootApi + versionUrl;

export const apiIdentityUrl = apiUrlRootApi + "identity/";
export const apiUrlLogin = apiIdentityUrl + "token/";
export const apiUrlLogout = apiIdentityUrl + "logout/";
export const apiUrlDynamicPassword = apiIdentityUrl + "GetDynamicPassword/";
export const apiUrlChangePassword = apiIdentityUrl + "change-password/";
export const apiUserNationalityCodeState = `${apiIdentityUrl}GetUserNationalityCode`;
export const apiAddNationalityCode = `${apiIdentityUrl}AddNationalityCode`;
export const GetUserInfoWithPhoneUrl = `${apiIdentityUrl}GetUserInfoWithPhone`;
export const GetUserIdentityCodeUrl = `${apiIdentityUrl}GetUserIdentityCode`;
export const SendUserIdentityCodeUrl = `${apiIdentityUrl}SendUserIdentityCode`;
export const apiUrlSendOtp = apiIdentityUrl + "sendotp/";
export const apiUrlVerifyOtp = apiIdentityUrl + "VerifyOtp/";
export const apiUrlCheckPhoneState = apiIdentityUrl + "CheckPhoneState/";
export const apiUrlRegister = apiIdentityUrl + "RegisterUser/";



export const apiFarmerUrl = apiVersionUrl + "farmer/";
export const apiFarmerProfileUrl = apiFarmerUrl + "profile/";
export const apiFarmerTextValueUrl = apiFarmerUrl + "textvalue/";

export const apiLegalEntityUrl = apiVersionUrl + "legalentity/";
export const apiLegalEntityProfileUrl = apiLegalEntityUrl + "profile/";
export const apiLegalEntityTextValueUrl = apiLegalEntityUrl + "textvalue/";

export const apiFarmUserUrl = apiVersionUrl + "farmUser/";
export const apiFarmUserProfileUrl = apiFarmUserUrl + "Profile/";
export const apiSendCodeIdentityUrl = apiFarmUserUrl + "SendFarmUserCode";
export const apiGetCodeIdentityUrl = apiFarmUserUrl + "GetCodeIdentity";
export const apiGetFarmUserExpertiseUrl = apiFarmUserUrl + "GetFarmUserExpertise";
export const apiSaveFarmUserExpertiseInfoUrl = apiFarmUserUrl + "SaveFarmUserExpertiseInfo";
export const apiFarmUserPartnerUrl = apiFarmUserUrl + "GetFarmUserPartner";

export const apiAgentUrl = apiVersionUrl + "agent/";
export const apiAgentProfileUrl = apiAgentUrl + "profile/";

export const apiAdvicerUrl = apiVersionUrl + "advicer/";
export const apiAdvicerProfileUrl = apiAdvicerUrl + "profile/";
export const apiGetExpertiseProfileUrl = apiAdvicerUrl + "GetAdvicerExpertise/";
export const apiSaveExpertiseProfileUrl = apiAdvicerUrl + "SaveAdvicerExpertiseInfo/";
export const apiAdvicerPartnerUrl = apiAdvicerUrl + "GetAdvicerPartner";



export const apiFarmUrl = apiVersionUrl + "farm/";
export const apiGetFarmsList = apiFarmUrl + "FarmsList/";
export const apiFarmFarmerUrl = apiFarmUrl + "Farmer/";
export const apiFarmLegalEntityUrl = apiFarmUrl + "LegalEntity/";
export const apiFarmTextValueUrl = apiFarmUrl + "textvalue/";
export const apiFarmFilterUrl = apiFarmUrl + "filter/";
export const apiFarmDelete = apiFarmUrl + "delete/";
export const apiFarmUpdate = apiFarmUrl + "update/";
export const apiFarmCultivationsEndDate = apiFarmUrl + "AddCultivationsEndDate/";
export const apiCheckMapImageUrl = apiFarmUrl + "CheckMapImage/";
export const apiCreateFarmWithArchive = apiFarmUrl + "CreateFarmWithArchive/";
export const apiDeleteArchive = apiFarmUrl + "DeleteArchive/";


export const apiDashboardUrl = apiVersionUrl + "Dashboard/";
export const apiDashboardUserCountUrl = apiDashboardUrl + "UserCount/";
export const apiDashboardCurrentUserAgentInfo = apiDashboardUrl + "GetAgentsInfo/";





export const apiFarmCostUrl = apiVersionUrl + "farmCost/";
export const apiFarmCostGetAll = `${apiFarmCostUrl}GetAll/`;
export const apiFarmCostFilter = `${apiFarmCostUrl}Report/`;
export const apiFarmCostGetById = `${apiFarmCostUrl}GetById/`;
export const apiFarmCostInsert = `${apiFarmCostUrl}Insert/`;
export const apiFarmCostEdit = `${apiFarmCostUrl}Edit/`;
export const apiFarmCostDelete = `${apiFarmCostUrl}Delete/`;


export const apiFarmOperationUrl = apiVersionUrl + "farmOperation/";
export const apiFarmOperationGetAll = `${apiFarmOperationUrl}GetAll/`;
export const apiFarmOperationGetWithMonth = `${apiFarmOperationUrl}GetWithMonth/`;
export const apiFarmOperationGetWithDay = `${apiFarmOperationUrl}GetWithDay`;
export const apiFarmOperationReport = `${apiFarmOperationUrl}Report/`;
export const apiFarmOperationGetById = `${apiFarmOperationUrl}GetById/`;
export const apiFarmOperationInsert = `${apiFarmOperationUrl}Insert/`;
export const apiFarmOperationEdit = `${apiFarmOperationUrl}Edit/`;
export const apiFarmOperationDelete = `${apiFarmOperationUrl}Delete/`;



export const apiFarmTensionUrl = apiVersionUrl + "farmTension/";
export const apiFarmTensionGetAll = `${apiFarmTensionUrl}GetAll/`;
export const apiFarmTensionGetAllByFarmId = `${apiFarmTensionUrl}GetAllByFarmId/`;
export const apiFarmTensionReport = `${apiFarmTensionUrl}Report/`;
export const apiFarmTensionGetById = `${apiFarmTensionUrl}GetById/`;
export const apiFarmTensionGetDeiall = `${apiFarmTensionUrl}GetDetail/`;
export const apiFarmTensionInsert = `${apiFarmTensionUrl}Insert/`;
export const apiFarmTensionEdit = `${apiFarmTensionUrl}Edit/`;
export const apiFarmTensionDelete = `${apiFarmTensionUrl}Delete/`;









export const apiAgentManagerUrl = `${apiVersionUrl}UserAgentManager/`;
export const apiAgentManagerGetById = `${apiAgentManagerUrl}GetById`;
export const apiAgentManagerFilter = `${apiAgentManagerUrl}Filter`;
export const apiAgentManagerGetAll = `${apiAgentManagerUrl}GetAll`;
export const apiAgentManagerInsert = `${apiAgentManagerUrl}Insert`;
export const apiAgentManagerEdit = `${apiAgentManagerUrl}Edit`;
export const apiAgentManagerDelete = `${apiAgentManagerUrl}Delete`;
export const apiAgentManagerGetDetail = `${apiAgentManagerUrl}GetDetail`;
export const apiAgentManagerUpdateState = `${apiAgentManagerUrl}UpdateState`;
export const apiAgentManagerUpdateIncreaseInventory = `${apiAgentManagerUrl}UpdateIncreaseInventory`;


export const apiAgentStateUrl = `${apiVersionUrl}AgentState/`;
export const apiAgentStateFilter = `${apiAgentStateUrl}Filter`;
export const apiAgentStateGetAll = `${apiAgentStateUrl}GetAll`;
export const apiAgentStateGetById = `${apiAgentStateUrl}GetById`;
export const apiAgentStateGetWithAgentId = `${apiAgentStateUrl}GetWithAgentId`;
export const apiAgentStateInsert = `${apiAgentStateUrl}Insert`;
export const apiAgentStateEdit = `${apiAgentStateUrl}Edit`;
export const apiAgentStateDelete = `${apiAgentStateUrl}Delete`;




export const apiFarmerManagerUrl = `${apiVersionUrl}UserFarmerManager/`;
export const apiFarmerManagerFilter = `${apiFarmerManagerUrl}Filter`;
export const apiFarmerManagerGetAll = `${apiFarmerManagerUrl}GetAll`;
export const apiFarmerManagerGetById = `${apiFarmerManagerUrl}GetById`;
export const apiFarmerManagerUpdateState = `${apiFarmerManagerUrl}UpdateState`;


export const apiAdvicerManagerUrl = `${apiVersionUrl}UserAdvicerManager/`;
export const apiAdvierManagerGetAll = `${apiAdvicerManagerUrl}GetAll`;
export const apiAdvierManagerGetDetail = `${apiAdvicerManagerUrl}GetDetail`;
export const apiAdvierManagerGetExpertiseDetail = `${apiAdvicerManagerUrl}GetExpertiseDetail`;
export const apiAdvierManagerUpdateExpertiseState = `${apiAdvicerManagerUrl}UpdateExpertiseState`;



export const apiFarmUserManagerUrl = `${apiVersionUrl}UserFarmUserManager/`;
export const apiFarmUserManagerGetAll = `${apiFarmUserManagerUrl}GetAll`;
export const apiFarmUserManagerGetDetail = `${apiFarmUserManagerUrl}GetDetail`;
export const apiFarmUserManagerGetExpertiseDetail = `${apiFarmUserManagerUrl}GetExpertiseDetail`;
export const apiFarmUserManagerUpdateExpertiseState = `${apiFarmUserManagerUrl}UpdateExpertiseState`;



export const apiLegalEntityManagerUrl = `${apiVersionUrl}UserLegalEntityManager/`;
export const apiLegalEntityManagerFilter = `${apiLegalEntityManagerUrl}Filter`;
export const apiLegalEntityManagerGetAll = `${apiLegalEntityManagerUrl}GetAll`;
export const apiLegalEntityManagerGetById = `${apiLegalEntityManagerUrl}GetById`;
export const apiLegalEntityManagerUpdateState = `${apiLegalEntityManagerUrl}UpdateState`;


export const apiCountryUrl = `${apiVersionUrl}Country/`;
export const apiCountryGetById = `${apiCountryUrl}GetById`;
export const apiCountryFilter = `${apiCountryUrl}Filter`;
export const apiCountryGetAll = `${apiCountryUrl}GetAll`;
export const apiCountryInsert = `${apiCountryUrl}Insert`;
export const apiCountryEdit = `${apiCountryUrl}Edit`;
export const apiCountryDelete = `${apiCountryUrl}Delete`;



export const apiProvinceUrl = `${apiVersionUrl}state/`;
export const apiProvinceGetById = `${apiProvinceUrl}GetById`;
export const apiProvinceFilter = `${apiProvinceUrl}Filter`;
export const apiProvinceGetAll = `${apiProvinceUrl}GetAll`;
export const apiProvinceInsert = `${apiProvinceUrl}Insert`;
export const apiProvinceEdit = `${apiProvinceUrl}Edit`;
export const apiProvinceDelete = `${apiProvinceUrl}Delete`;
export const apiGetProvinceAllCountry = `${apiCountryUrl}GetAll`;



export const apiCityUrl = `${apiVersionUrl}City/`;
export const apiCityGetById = `${apiCityUrl}GetById`;
export const apiCityFilter = `${apiCityUrl}Filter`;
export const apiCityGetAll = `${apiCityUrl}GetAll`;
export const apiCityInsert = `${apiCityUrl}Insert`;
export const apiCityEdit = `${apiCityUrl}Edit`;
export const apiCityDelete = `${apiCityUrl}Delete`;
export const apiCityGetAllState = `${apiProvinceUrl}GetAll`;


export const apiProductTypeUrl = `${apiVersionUrl}ProductType/`;
export const apiProductTypeGetById = `${apiProductTypeUrl}GetById`;
export const apiProductTypeFilter = `${apiProductTypeUrl}Filter`;
export const apiProductTypeGetAll = `${apiProductTypeUrl}GetAll`;
export const apiProductTypeInsert = `${apiProductTypeUrl}Insert`;
export const apiProductTypeEdit = `${apiProductTypeUrl}Edit`;
export const apiProductTypeDelete = `${apiProductTypeUrl}Delete`;


export const apiProductUrl = `${apiVersionUrl}Product/`;
export const apiProductGetById = `${apiProductUrl}GetById`;
export const apiProductFilter = `${apiProductUrl}Filter`;
export const apiProductGetAll = `${apiProductUrl}GetAll`;
export const apiProductInsert = `${apiProductUrl}Insert`;
export const apiProductEdit = `${apiProductUrl}Edit`;
export const apiProductDelete = `${apiProductUrl}Delete`;
export const apiProductGatAllType = `${apiProductTypeUrl}GetAll`;


export const apiBannerUrl = `${apiVersionUrl}Banner/`;
export const apiBannerGetById = `${apiBannerUrl}GetById`;
export const apiBannerFilter = `${apiBannerUrl}Filter`;
export const apiBannerGetAll = `${apiBannerUrl}GetAll`;
export const apiBannerGetAllClient = `${apiBannerUrl}GetAllClient`;
export const apiBannerInsert = `${apiBannerUrl}Insert`;
export const apiBannerEdit = `${apiBannerUrl}Edit`;
export const apiBannerDelete = `${apiBannerUrl}Delete`;
export const apiBannerGetForDashbord = `${apiBannerUrl}GetForDashbord`;


export const apiBannerProductUrl = `${apiVersionUrl}BannerProduct/`;
export const apiBannerProductGetById = `${apiBannerProductUrl}GetById`;
export const apiBannerProductFilter = `${apiBannerProductUrl}Filter`;
export const apiBannerProductGetAll = `${apiBannerProductUrl}GetAll`;
export const apiBannerProductInsert = `${apiBannerProductUrl}Insert`;
export const apiBannerProductEdit = `${apiBannerProductUrl}Edit`;
export const apiBannerProductDelete = `${apiBannerProductUrl}Delete`;
export const apiBannerProductGetForDashbord = `${apiBannerProductUrl}GetForDashbord`;
export const apiBannerProductGetAllClient = `${apiBannerProductUrl}GetAllClient`;


export const apiMeteorologyUrl = apiVersionUrl + "Meteorology/";
export const apiMeteorologyGetAll = `${apiMeteorologyUrl}GetAll/`;
export const apiMeteorologyFilter = `${apiMeteorologyUrl}Filter/`;
export const apiMeteorologyGetById = `${apiMeteorologyUrl}GetById/`;
export const apiMeteorologyGetByIdDetail = `${apiMeteorologyUrl}GetByIdDetial/`;
export const apiMeteorologyInsert = `${apiMeteorologyUrl}Insert/`;
export const apiMeteorologyEdit = `${apiMeteorologyUrl}Edit/`;
export const apiMeteorologyDelete = `${apiMeteorologyUrl}Delete/`;
export const apiMeteorologyGetMessage = `${apiMeteorologyUrl}GetMessage/`;
export const apiMeteorologyGetWeather = `${apiMeteorologyUrl}GetWeather/`;
export const apiMeteorologyGetWeatherDaily = `${apiMeteorologyUrl}GetWeatherDaily/`;

export const apiMeteorologyConditionUrl = apiVersionUrl + "MeteorologyCondition/";
export const apiMeteorologyConditionGetAll = `${apiMeteorologyConditionUrl}GetAll/`;
export const apiMeteorologyConditionFilter = `${apiMeteorologyConditionUrl}Filter/`;
export const apiMeteorologyConditionGetById = `${apiMeteorologyConditionUrl}GetById/`;
export const apiMeteorologyConditionInsert = `${apiMeteorologyConditionUrl}Insert/`;
export const apiMeteorologyConditionEdit = `${apiMeteorologyConditionUrl}Edit/`;
export const apiMeteorologyConditionDelete = `${apiMeteorologyConditionUrl}Delete/`;


export const apiAdviceGroupUrl = `${apiVersionUrl}AdviceGroup/`;
export const apiAdviceGroupGetById = `${apiAdviceGroupUrl}GetById`;
export const apiAdviceGroupFilter = `${apiAdviceGroupUrl}Filter`;
export const apiAdviceGroupGetAll = `${apiAdviceGroupUrl}GetAll`;
export const apiAdviceGroupInsert = `${apiAdviceGroupUrl}Insert`;
export const apiAdviceGroupEdit = `${apiAdviceGroupUrl}Edit`;
export const apiAdviceGroupDelete = `${apiAdviceGroupUrl}Delete`;
export const apiAdviceGroupChangeState = `${apiAdviceGroupUrl}ChangeState`;

export const apiAdviceUrl = `${apiVersionUrl}Advice/`;
export const apiAdviceGetById = `${apiAdviceUrl}GetById`;
export const apiGetAllWithUserFarms = `${apiAdviceUrl}GetAllWithUserFarms`;
export const apiAdviceGetAll = `${apiAdviceUrl}GetAllAdmin`;
export const apiAdviceGetAllWithGroup = `${apiAdviceUrl}GetAllWithGroup`;
export const apiAdviceInsert = `${apiAdviceUrl}Insert`;
export const apiAdviceEdit = `${apiAdviceUrl}Edit`;
export const apiAdviceDelete = `${apiAdviceUrl}Delete`;
export const apiAdviceWithFarmId = `${apiAdviceUrl}GetWithFarmId`;
export const apiGetAdviceInfoById = `${apiAdviceUrl}GetAdviceInfoById`;


export const apiDietPlanUrl = `${apiVersionUrl}DietPlan/`;
export const apiDietPlanGetById = `${apiDietPlanUrl}GetById`;
export const apiDietPlanGetAll = `${apiDietPlanUrl}GetAllAdmin`;
export const apiDietPlanInsert = `${apiDietPlanUrl}Insert`;
export const apiDietPlanEdit = `${apiDietPlanUrl}Edit`;
export const apiDietPlanDelete = `${apiDietPlanUrl}Delete`;
export const apiDietPlanWithFarmId = `${apiDietPlanUrl}GetWithFarmId`;
export const apiDietPlanGetAllWithUserFarms = `${apiDietPlanUrl}GetAllWithUserFarms`;









export const apiFarmerFarmUserUrl = `${apiVersionUrl}FarmerFarmUser/`;
export const apiGetAllFarmUserWithFarmerIdUrl = `${apiFarmerFarmUserUrl}GetAllWithFarmerId/`;
export const apiGetFarmUserWithCodeUrl = `${apiFarmerFarmUserUrl}GetFarmUserWithCode/`;
export const apiGetFarmUserDetailWithIdUrl = `${apiFarmerFarmUserUrl}GetFarmUserDetailWithId/`;
export const apiAddFarmUserToFarmerUrl = `${apiFarmerFarmUserUrl}AddFarmUserToFarmer/`;
export const apiFarmerFarmUserDeleteUrl = `${apiFarmerFarmUserUrl}Delete/`;



export const apiGeoDataBaseTypeUrl = `${apiVersionUrl}GeoDataBaseType/`;
export const apiGeoDataBaseTypeGetById = `${apiGeoDataBaseTypeUrl}GetById`;
export const apiGeoDataBaseTypeGetAll = `${apiGeoDataBaseTypeUrl}GetAll`;
export const apiGeoDataBaseTypeInsert = `${apiGeoDataBaseTypeUrl}Insert`;
export const apiGeoDataBaseTypeEdit = `${apiGeoDataBaseTypeUrl}Edit`;
export const apiGeoDataBaseTypeDelete = `${apiGeoDataBaseTypeUrl}Delete`;

export const apiGeoDataBaseUrl = `${apiVersionUrl}GeoDataBase/`;
export const apiGeoDataBaseGetById = `${apiGeoDataBaseUrl}GetById`;
export const apiGeoDataBaseGetAll = `${apiGeoDataBaseUrl}GetAllWithUser`;
export const apiGeoDataBaseGetAllWithFarmId = `${apiGeoDataBaseUrl}GetAllWithFarmId`;
export const apiGeoDataBaseInsert = `${apiGeoDataBaseUrl}Insert`;
export const apiGeoDataBaseEdit = `${apiGeoDataBaseUrl}Edit`;
export const apiGeoDataBaseDelete = `${apiGeoDataBaseUrl}Delete`;

export const apiIndicatorUrl = `${apiVersionUrl}IndicatorManager/`;
export const apiIndicatorGetAll = `${apiIndicatorUrl}GetAll`;
export const apiIndicatorGetById = `${apiIndicatorUrl}GetById`;
export const apiIndicatorInsert = `${apiIndicatorUrl}Insert`;
export const apiIndicatorEdit = `${apiIndicatorUrl}Edit`;
export const apiIndicatorDelete = `${apiIndicatorUrl}Delete`;
export const apiIndicatorGetState = `${apiIndicatorUrl}IndicatorStateGet`;
export const apiIndicatorSaveState = `${apiIndicatorUrl}IndicatorStateSave`;
export const apiChangeUserIndicatorAccess = `${apiIndicatorUrl}ChangeUserIndicatorAccess`;




export const apiClientIndicatorUrl = `${apiVersionUrl}Indicator/`;
export const apiClientIndicatorGetAll = `${apiClientIndicatorUrl}GetAllIndicatorWithProduct`;
export const apiClientIndicatorAvailableDay = `${apiClientIndicatorUrl}GetAvailableDay`;
export const apiClientIndicatorGet = `${apiClientIndicatorUrl}GetIndicator`;
export const apiClientIndicatorGetState = `${apiClientIndicatorUrl}GetIndicatorSTATE`;
export const apiGetIndicatorDateTimes = `${apiClientIndicatorUrl}GetIndicatorDateTimes`;




export const apiTiketUrl = `${apiVersionUrl}Tiket/`;
export const apiTiketGetAllWithUser = `${apiTiketUrl}GetAllWithUser`;
export const apiTiketGetAll = `${apiTiketUrl}GetAll`;
export const apiTiketGetAnswer = `${apiTiketUrl}GetTiketAnswer`;
export const apiTiketNew = `${apiTiketUrl}TitketNew`;
export const apiTiketClose = `${apiTiketUrl}CloseTiket`;
export const apiTiketGetById = `${apiTiketUrl}GetById`;
export const apiTiketAnswer = `${apiTiketUrl}TitketAnswer`;
export const apiTiketChangeState = `${apiTiketUrl}TiketChangeState`;


//Financial Manager Url --------------------------------------Start
//#region UserTypeCostManager
export const apiUserTypeCostUrl = `${apiVersionUrl}UserTypeCost/`;
export const apiUserTypeCostUrlGetAll = `${apiUserTypeCostUrl}GetAll`;
export const apiUserTypeCostUrlGetById = `${apiUserTypeCostUrl}GetById`;
export const apiUserTypeCostUrlInsert = `${apiUserTypeCostUrl}Insert`;
export const apiUserTypeCostUrlEdit = `${apiUserTypeCostUrl}Edit`;
export const apiUserTypeCostUrlDelete = `${apiUserTypeCostUrl}Delete`;
//#endregion
//#region FinancialAccountManager
export const apiFinancialAccountManagerUrl = `${apiVersionUrl}FinancialAccountManager`;
export const apiGetAllFinancialAccount = `${apiFinancialAccountManagerUrl}/GetAllFinancialAccount`;
export const apiChangeAccountState = `${apiFinancialAccountManagerUrl}/ChangeAccountState`;
export const apiGetAccountDetail = `${apiFinancialAccountManagerUrl}/GetAccountDetail`;
export const apiGetLatestTransaction = `${apiFinancialAccountManagerUrl}/GetLatestTransaction`;
export const apiGetAllTransaction = `${apiFinancialAccountManagerUrl}/GetAllTransaction`;
export const apiGetAllCustomOfferCode = `${apiFinancialAccountManagerUrl}/GetAllCustomOfferCode`;
export const apiGetCustomOfferCodeById = `${apiFinancialAccountManagerUrl}/GetCustomOfferCodeById`;
export const apiInsertCustomOfferCode = `${apiFinancialAccountManagerUrl}/InsertCustomOfferCode`;
export const apiUpdateCustomOfferCode = `${apiFinancialAccountManagerUrl}/UpdateCustomOfferCode`;
export const apiDeleteCustomOfferCode = `${apiFinancialAccountManagerUrl}/DeleteCustomOfferCode`;
export const apiGetUserInfoByAccountId = `${apiFinancialAccountManagerUrl}/GetUserInfoByAccountId`;
export const apiGetCustomCostType = `${apiFinancialAccountManagerUrl}/GetCustomCostType`;
export const apiUpdateUserTypeCost = `${apiFinancialAccountManagerUrl}/UpdateUserTypeCost`;
//#endregion

//#region DiscountManager
export const apiDiscountManagerUrl = `${apiVersionUrl}FinancialDisountManager/`;
//===========Festival
export const apiGetAllFestival = `${apiDiscountManagerUrl}GetAllFestival`;
export const apiGetAllWithDateTime = `${apiDiscountManagerUrl}GetAllWithDateTime`;
export const apiGetByIdFestival = `${apiDiscountManagerUrl}GetByIdFestival`;
export const apiInsertFestival = `${apiDiscountManagerUrl}InsertFestival`;
export const apiEditFestival = `${apiDiscountManagerUrl}EditFestival`;
export const apiDeleteFestival = `${apiDiscountManagerUrl}DeleteFestival`;

//===========OfferCode
export const apiGetAllOfferCode = `${apiDiscountManagerUrl}GetAllOfferCode`;
export const apiGetByIdOfferCode = `${apiDiscountManagerUrl}GetByIdOfferCode`;
export const apiGetWithCodeOfferCode = `${apiDiscountManagerUrl}GetWithCodeOfferCode`;
export const apiInsertOfferCode = `${apiDiscountManagerUrl}InsertOfferCode`;
export const apiEditOfferCode = `${apiDiscountManagerUrl}EditOfferCode`;
export const apiDeleteOfferCode = `${apiDiscountManagerUrl}DeleteOfferCode`;

//#endregion

//Financial Manager Url --------------------------------------End




//Financial User Url ---------------------------------Start
//#region FinancialUser
export const apiFinancialUserUrl = `${apiVersionUrl}FinancialUser/`
export const apiFinancialUserGetInventory = `${apiFinancialUserUrl}GetUserInventory`;
export const apiFinancialUserTypeCost = `${apiFinancialUserUrl}GetUserTypeCost`;
export const apiFinancialUserCheck = `${apiFinancialUserUrl}CheckFinancialAccount`;
//#endregion

//#region FinancailUserAccount
export const apiFinancialUserAccountUrl = `${apiVersionUrl}FinancailUserAccount/`
export const apiGetFinanciailAccountInfo = `${apiFinancialUserAccountUrl}GetUserFinancialAccountInfo`;
export const apiLatestIncreseAndDecrease = `${apiFinancialUserAccountUrl}LatestIncreseAndDecrease`;
export const apiGetBuyServicePaymentWithAccount = `${apiFinancialUserAccountUrl}GetBuyServicePaymentWithAccount`;
export const apiGetAllBuyServiceDetailWithId = `${apiFinancialUserAccountUrl}GetAllBuyServiceDetailWithId`;
export const apiGetAllAccountCustomOfferCode = `${apiFinancialUserAccountUrl}GetAllAccountCustomOfferCode`;
export const apiCheckDisCountCode = `${apiFinancialUserAccountUrl}CheckDisCountCode`;
export const apiIncreseAccountInventory = `${apiFinancialUserAccountUrl}IncreseFinancialAccountInventory`;
export const apiGetTransationWithId = `${apiFinancialUserAccountUrl}GetTransationWithId`;
//#endregion FinancailUserAccount

//#region FinancailUserReport
export const apiFinancailUserReportUrl = `${apiVersionUrl}FinancailUserReport/`
export const apiReportGetAllTransaction = `${apiFinancailUserReportUrl}GetAllTransaction`;
export const apiReportGetAllBuyServiceWithUserId = `${apiFinancailUserReportUrl}GetAllBuyServiceWithUserId`;
export const apiReportGetAllBuyService = `${apiFinancailUserReportUrl}GetAllBuyService`;
export const apiReportGetBuyServiceDetail = `${apiFinancailUserReportUrl}GetBuyServiceDetail`;
export const apiReportGetFarmInfo = `${apiFinancailUserReportUrl}GetFarmInfo`;
export const apiReportGetAllFarmAndDateTimeCurrentUser = `${apiFinancailUserReportUrl}GetAllFarmAndDateTimeCurrentUser`;
export const apiReportGetBuyServiceInfoWithDetailId = `${apiFinancailUserReportUrl}GetBuyServiceInfoWithDetailId`;
//#endregion

//#region FinancailUserShopReport
export const apiFinancailUserShopReportUrl = `${apiVersionUrl}FinancailUserShopReport/`
export const apiShopReportGetAllBuyService = `${apiFinancailUserShopReportUrl}GetAllBuyService`;
export const apiShopReportGetBuyServiceDetail = `${apiFinancailUserShopReportUrl}GetBuyServiceDetail`;
export const apiShopReportGetFarmInfo = `${apiFinancailUserShopReportUrl}GetFarmInfo`;
//#endregion

//#region FinancialUserSentinelService
export const apiFinancialUserSentinelServiceUrl = `${apiVersionUrl}FinancialUserSentinelService/`
export const apiSentinelGetFinancialAccountInfo = `${apiFinancialUserSentinelServiceUrl}GetFinancialAccountInfo`;
export const apiSentinelGetLatestBuyService = `${apiFinancialUserSentinelServiceUrl}GetLatestBuyService`;
export const apiSentinelGetAllBuyServiceDetailWithId = `${apiFinancialUserSentinelServiceUrl}GetAllBuyServiceDetailWithId`;
export const apiSentinelGetFarmDetailById = `${apiFinancialUserSentinelServiceUrl}GetFarmDetailById`;
export const apiSentinelGetFarmAllDateTimes = `${apiFinancialUserSentinelServiceUrl}GetFarmAllDateTimes`;
export const apiGetUserCostCenter = `${apiFinancialUserSentinelServiceUrl}GetUserCostCenter`;
export const apiAccountRequestInvoice = `${apiFinancialUserSentinelServiceUrl}AccountRequestInvoice`;
export const apiSuccessWithAccountPayment = `${apiFinancialUserSentinelServiceUrl}SuccessWithAccountPayment`;
export const apiBankRequestInvoice = `${apiFinancialUserSentinelServiceUrl}BankRequestInvoice`;
export const apiSuccessWithBankPayment = `${apiFinancialUserSentinelServiceUrl}SuccessWithBankPayment`;
export const apiGetBuyServicePerfixWithId = `${apiFinancialUserSentinelServiceUrl}GetBuyServicePrefixPayWithId`;
export const apiDeletePrefixInfo = `${apiFinancialUserSentinelServiceUrl}DeletePrefixInfo`;
//#endregion

//#region FinancialUserDiscounts
export const apiFinancialUserDiscountsUrl = `${apiVersionUrl}FinancialUserDiscounts/`
export const apiDiscountsGetAllFestival = `${apiFinancialUserDiscountsUrl}GetAllFestival`;
export const apiDiscountsGetAllPublicOfferCode = `${apiFinancialUserDiscountsUrl}GetAllPublicOfferCode`;
export const apiDiscountsGetAllCustomOfferCode = `${apiFinancialUserDiscountsUrl}GetAllCustomOfferCode`;
export const apiDiscountsGetOfferCodeWithCode = `${apiFinancialUserDiscountsUrl}OfferCodeGetWithCode`;
export const apiDiscountsGetFestivalWithDate = `${apiFinancialUserDiscountsUrl}GetFestivalWithDate`;
//#endregion
//Financail User Url ---------------------------------End


//#region HelpMove
export const apiHelpMoveUrl = `${apiVersionUrl}HelpMove/`
export const apiGetAllHelpMove = `${apiHelpMoveUrl}GetAll`;
export const apiGetByIdHelpMove = `${apiHelpMoveUrl}GetById`;
export const apiInsertHelpMove = `${apiHelpMoveUrl}Insert`;
export const apiEditHelpMove = `${apiHelpMoveUrl}Edit`;
export const apiDeleteHelpMove = `${apiHelpMoveUrl}Delete`;

export const apiAppHelpMoveUrl = `${apiVersionUrl}AppHelpMove/`
export const apiGetAllAppHelpMove = `${apiAppHelpMoveUrl}GetAll`;
export const apiGetByIdAppHelpMove = `${apiAppHelpMoveUrl}GetById`;
export const apiInsertAppHelpMove = `${apiAppHelpMoveUrl}Insert`;
export const apiEditAppHelpMove = `${apiAppHelpMoveUrl}Edit`;
export const apiDeleteAppHelpMove = `${apiAppHelpMoveUrl}Delete`;
//#endregion

//#region HelpQuestion
export const apiHelpQuestionUrl = `${apiVersionUrl}HelpQuestion/`
export const apiGetAllHelpQuestion = `${apiHelpQuestionUrl}GetAll`;
export const apiGetByIdHelpQuestion = `${apiHelpQuestionUrl}GetById`;
export const apiInsertHelpQuestion = `${apiHelpQuestionUrl}Insert`;
export const apiEditHelpQuestion = `${apiHelpQuestionUrl}Edit`;
export const apiDeleteHelpQuestion = `${apiHelpQuestionUrl}Delete`;

export const apiAppHelpQuestionUrl = `${apiVersionUrl}AppHelpQuestion/`
export const apiGetAllAppHelpQuestion = `${apiAppHelpQuestionUrl}GetAll`;
export const apiGetByIdAppHelpQuestion = `${apiAppHelpQuestionUrl}GetById`;
export const apiInsertAppHelpQuestion = `${apiAppHelpQuestionUrl}Insert`;
export const apiEditAppHelpQuestion = `${apiAppHelpQuestionUrl}Edit`;
export const apiDeleteAppHelpQuestion = `${apiAppHelpQuestionUrl}Delete`;
//#endregion



// #region ConnectionAgent
export const apiConnectionAgentUrl = `${apiVersionUrl}ConnectionAgent/`
export const apiGetAllAgentFarms = `${apiConnectionAgentUrl}GetAgentAllFarms`;
export const apiGetAgentFarmDetail = `${apiConnectionAgentUrl}GetFarmDetail/`;
export const apiGetFarmFarmuser = `${apiConnectionAgentUrl}GetFarmFarmusers`;
export const apiGetFarmAdvicer = `${apiConnectionAgentUrl}GetFarmAdvicers`;
export const apiGetFarmFarmer = `${apiConnectionAgentUrl}GetFarmFarmer`;
export const apiGetAllAgentFarmers = `${apiConnectionAgentUrl}GetAllAgentFarmers`;
export const apiGetFarmerAllFarms = `${apiConnectionAgentUrl}GetFarmerAllFarms`;
export const apiGetAllAgentLegalEntities = `${apiConnectionAgentUrl}GetAllAgentLegalEntities`;
export const apiLegalEntitySelectedFarms = `${apiConnectionAgentUrl}LegalEntitySelectedFarms`;
export const apiGetAllAgentAdvicers = `${apiConnectionAgentUrl}GetAllAgentAdvicers`;
export const apiAdvicerSelectedFarms = `${apiConnectionAgentUrl}AdvicerSelectedFarms`;
export const apiGetAllAgentFarmusers = `${apiConnectionAgentUrl}GetAllAgentFarmusers`;
export const apiFarmuserSelectedFarms = `${apiConnectionAgentUrl}FarmuserSelectedFarms`;
// #endregion

 


// #region ConnectionFarmer
export const apiConnectionFarmerUrl = `${apiVersionUrl}ConnectionFarmer/`
export const apiFarmerLegalEntityFarms = `${apiConnectionFarmerUrl}LegalEntityFarms`;
export const apiFarmerLegalEntityIncreaseInventoryAccount = `${apiConnectionFarmerUrl}LegalEntityIncreaseInventoryAccount`;
export const apiFarmerLegalEntityBuySentinel = `${apiConnectionFarmerUrl}LegalEntityBuySentinel`;
export const apiFarmerLegalEntityBuySentinelFaktorFarm = `${apiConnectionFarmerUrl}LegalEntityBuySentinelFaktorFarm`;
export const apiFarmerLegalEntityAdvicers = `${apiConnectionFarmerUrl}LegalEntityAdvicers`;
export const apiFarmerLegalEntityAdvicerFarms = `${apiConnectionFarmerUrl}LegalEntityAdvicerFarms`;
export const apiFarmerLegalEntityAdvicerLastFarmWorkReport = `${apiConnectionFarmerUrl}LegalEntityAdvicerLastFarmWorkReport`;
export const apiFarmerLegalEntityFarmusers = `${apiConnectionFarmerUrl}LegalEntityFarmusers`;
export const apiFarmerLegalEntityFarmuserFarms = `${apiConnectionFarmerUrl}LegalEntityFarmuserFarms`;
export const apiFarmerLegalEntityFarmuserLastFarmWorkReport = `${apiConnectionFarmerUrl}LegalEntityFarmuserLastFarmWorkReport`;

export const apiGetAllFarmerAdvicer = `${apiConnectionFarmerUrl}AdvicerGetAll`;
export const apiFarmerAdvicerDetailInfo =  `${apiConnectionFarmerUrl}AdvicerDetailInfo`;
export const apiFarmerAdvicerGetInfoWithCode =  `${apiConnectionFarmerUrl}AdvicerGetInfoWithCode`;
export const apiFarmerAdvicerRegister =  `${apiConnectionFarmerUrl}AdvicerRegister`;
export const apiFarmerAdvicerLatestWorkReport =  `${apiConnectionFarmerUrl}AdvicerLatestWorkReport`;
export const apiFarmerAdvicerAllFarms =  `${apiConnectionFarmerUrl}AdvicerAllFarms`;
export const apiFarmerAdvicerDelete =  `${apiConnectionFarmerUrl}AdvicerDelete`;
export const apiFarmerAdvicerDeleteFarm =  `${apiConnectionFarmerUrl}AdvicerDeleteFarm`;
export const apiFarmerAdvicerAddFarm =  `${apiConnectionFarmerUrl}AdvicerAddFarm`;
export const apiFarmerAdvicerGetAllNotAccessFarms =  `${apiConnectionFarmerUrl}AdvicerGetAllNotAccessFarms`;


export const apiGetAllFarmerFarmuser = `${apiConnectionFarmerUrl}FarmuserGetAll`;
export const apiFarmerFarmuserDetailInfo =  `${apiConnectionFarmerUrl}FarmuserDetailInfo`;
export const apiFarmerFarmuserGetInfoWithCode =  `${apiConnectionFarmerUrl}FarmuserGetInfoWithCode`;
export const apiFarmerFarmuserRegister =  `${apiConnectionFarmerUrl}FarmuserRegister`;
export const apiFarmerFarmuserAllFarms =  `${apiConnectionFarmerUrl}FarmuserAllFarms`;
export const apiFarmerFarmuserDelete =  `${apiConnectionFarmerUrl}FarmuserDelete`;
export const apiFarmerFarmuserDeleteFarm =  `${apiConnectionFarmerUrl}FarmuserDeleteFarm`;
export const apiFarmerFarmuserAddFarm =  `${apiConnectionFarmerUrl}FarmuserAddFarm`;


// #endregion


// #region ConnectionFarmUser
export const apiConnectionFarmuserUrl = `${apiVersionUrl}ConnectionFarmuser/`
export const apiFarmuserAllEmployerFarm = `${apiConnectionFarmuserUrl}FarmuserAllEmployerFarm`;
// #endregion


// #region ConnectionAdvicer
export const apiConnectionAdvicerUrl = `${apiVersionUrl}ConnectionAdvicer/`
export const apiAdvicerAllEmployerFarm = `${apiConnectionAdvicerUrl}AdvicerAllEmployerFarm`;
// #endregion


// #region searchEngin
export const apiFinancialSearchEnginUrl = `${apiVersionUrl}FinancialSearchEngin/`
export const apiFindUsers = `${apiFinancialSearchEnginUrl}FindUsers`;
export const apiGetUserAccountTrnasaction = `${apiFinancialSearchEnginUrl}GetUserAccountTrnasaction`;
export const apiGetUserBuySentinelServiceInfo = `${apiFinancialSearchEnginUrl}GetUserBuySentinelServiceInfo`;
export const apiGetUserAllAccessDateTimes = `${apiFinancialSearchEnginUrl}GetUserAllAccessDateTimes`;
export const apiGetUserAllPerfixPayInfo = `${apiFinancialSearchEnginUrl}GetUserAllPerfixPayInfo`;
//#endregion

// #region buyServiceReport
export const apiBuyServiceReportUrl = `${apiVersionUrl}FinancialBuyServiceReport/`
export const apiBuyServiceGetInfo = `${apiBuyServiceReportUrl}BuyServiceGetInfo`;
// #endregion


// #region FinancialAccountIncreaseReport
export const apiFinancialAccountIncreaseReportUrl = `${apiVersionUrl}FinancialAccountIncreaseReport/`
export const apiGetLatestIncrease = `${apiFinancialAccountIncreaseReportUrl}GetLatestIncrease`;
export const apiGetIncreaseReport = `${apiFinancialAccountIncreaseReportUrl}GetIncreaseReport`;
export const apiGetAccountInfo = `${apiFinancialAccountIncreaseReportUrl}GetAccountInfo`;
export const apiGetUserInfo = `${apiFinancialAccountIncreaseReportUrl}GetUserInfo`;
// #endregion

export const apiFarmerReportUrl = `${apiVersionUrl}FarmerReport/`
export const apiFarmDateReport = `${apiFarmerReportUrl}FarmDateReport`;
export const apiFinancialAccountReport = `${apiFarmerReportUrl}FinancialAccountReport`;
export const apiBuyServiceReport = `${apiFarmerReportUrl}BuyServiceReport`;


export const apiCorrespondencesUrl = `${apiVersionUrl}Correspondences/`
export const apiGetUserTypeAccessToChat = `${apiCorrespondencesUrl}GetUserTypeAccessToChat/`
export const apiGetAllUsersInfoRelated = `${apiCorrespondencesUrl}GetAllUsersInfoRelated/`
export const apiGetUserChatHistory = `${apiCorrespondencesUrl}GetUserChatHistory/`
export const apiGetChatDetailWithUserId = `${apiCorrespondencesUrl}GetChatDetailWithUserId/`


export const apiExpertiseUrl = `${apiVersionUrl}Expertise/`
export const apiGetAllExpertise = `${apiExpertiseUrl}GetAll/`
export const apiGetByIdExpertise = `${apiExpertiseUrl}GetById/`
export const apiInsertExpertise = `${apiExpertiseUrl}Insert/`
export const apiEditExpertise = `${apiExpertiseUrl}Edit/`
export const apiDeleteExpertise = `${apiExpertiseUrl}Delete/`


export const apiTipsUrl = `${apiVersionUrl}Tips/`
export const apiGetAllTips = `${apiTipsUrl}GetAllTips/`
export const apiGetAllEmployerTips = `${apiTipsUrl}GetAllEmployerTips/`
export const apiGetAllTipsAndReply = `${apiTipsUrl}GetAllTipsAndReply/`
export const apiGetTips = `${apiTipsUrl}GetTips/`
export const apiNewTips = `${apiTipsUrl}NewTips/`
export const apiNewTipsReply = `${apiTipsUrl}NewTipsReply/`
export const apiEditTips = `${apiTipsUrl}EditTips/`
export const apiDeleteTipsFile = `${apiTipsUrl}DeleteTipsFile/`
export const apiDeleteReplyFile = `${apiTipsUrl}DeleteReplyFile/`
export const apiGetLastTips = `${apiTipsUrl}GetLastTips/`



export const apiColleaguesRequest = `${apiVersionUrl}ColleaguesRequest/`
export const apiGetAllRequestColleagues = `${apiColleaguesRequest}GetAllRequestColleagues/`
export const apiGetAllAdvicer = `${apiColleaguesRequest}GetAllColleaguesAdvicer/`
export const apiGetAllFarmuser = `${apiColleaguesRequest}GetAllColleaguesFarmuser/`

export const isAuthGuardActive = true;
export const themeRadiusStorageKey = "theme_radius";
export const themeSelectedColorStorageKey = "theme_selected_color";
export const defaultColor = "light.blueolympic";
export const colors = [
	"bluenavy",
	"blueyale",
	"blueolympic",
	"greenmoss",
	"greenlime",
	"purplemonster",
	"orangecarrot",
	"redruby",
	"yellowgranola",
	"greysteel",
];
