import axios from "axios";
import { apiGetAllRequestColleagues } from "../constants/config";
import { apiGetAllAdvicer } from "../constants/config";
import { apiGetAllFarmuser } from "../constants/config";

async function AllRequestColleagues(state, fullName, fromDate, toDate) {
  try {
    let data = {
      State: state,
      FullName: fullName,
      FromDate: fromDate,
      ToDate: toDate,
    };
    let promise = await axios
      .post(apiGetAllRequestColleagues, data, {})
      .then((res) => { 
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetAllColleaguesAdvicer(searchText) {
  try {
    let data = {
      SearchText: searchText,
    };
    let promise = await axios
      .post(apiGetAllAdvicer, data, {})
      .then((res) => { 
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

async function GetAllColleaguesFarmuser(searchText) {
  try {
    let data = {
      SearchText: searchText,
    };
    let promise = await axios
      .post(apiGetAllFarmuser, data, {})
      .then((res) => { 
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

//   async function GetById(id) {
//     try {
//       let url = `${apiCityGetById}/${id}`;
//       let promise = await axios
//         .get(url , {}).then((res) => {
//           return res.data.data;
//         })
//         .catch((err) => {
//           if (err.response)
//             console.log("catch from City" + err.response.data.Message);
//         });
//           return promise;
//     } catch (err) {
//       console.log(err);
//     }

//     return false;
//   }

export const ColleaguesService = {
  AllRequestColleagues,
  GetAllColleaguesAdvicer,
  GetAllColleaguesFarmuser
};
